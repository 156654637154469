import { motion } from 'framer-motion'
import { useEffect, useState, type HTMLAttributes } from 'react'

const variants = {
  initial: { opacity: 0, y: 80 },
  animateIn: (custom: number) => ({
    opacity: 1,
    y: 0,
    transition: { duration: 0.8, ease: 'easeOut', delay: custom * 0.5 },
  }),
}

export const AnimateIn = <T extends keyof typeof motion>({
  children,
  index,
  as,
  ...props
}: {
  children: React.ReactNode
  index?: number
  as?: T
} & HTMLAttributes<T>) => {
  const [_isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  const MotionComponent = motion[as ?? 'div']

  return (
    // @ts-expect-error
    <MotionComponent
      {...props}
      custom={index ?? 0}
      variants={variants}
      whileInView='animateIn'
      viewport={{ once: true }}
      initial='initial'
      // animate={isMounted ? 'animateIn' : undefined}>
      // animate='animateIn'
    >
      {children}
    </MotionComponent>
  )
}
