export const HowItWorks1 = ({ className }: { className?: string }) => (
  <svg
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    viewBox='0 0 293.11 178.35'
    xmlSpace='preserve'
    className={className}>
    <circle fill='#FFFFFF' cx='146.56' cy='107.82' r='50.37' />
    <circle
      className='opacity-25'
      fill='#00AECE'
      cx='146.56'
      cy='107.82'
      r='50.37'
    />
    <circle fill='#00AECE' cx='146.56' cy='107.82' r='31.8' />

    <rect x='109.63' y='93.76' fill='none' width='70.31' height='37.68' />
    <text
      transform='matrix(1 0 0 1 138.3061 119.1704)'
      className='font-stolzl-bold [font-size:33px]'
      fill='#FFFFFF'>
      1
    </text>

    <linearGradient
      id='HowItWorks1-1'
      gradientUnits='userSpaceOnUse'
      x1='44.3963'
      y1='113.1594'
      x2='236.1817'
      y2='71.6474'>
      <stop offset='0' stopColor='#00AECE' />
      <stop offset='0.4863' stopColor='#8A2FF1' />
      <stop offset='1' stopColor='#FD67FF' />
    </linearGradient>
    <circle fill='url(#HowItWorks1-1)' cx='64.13' cy='108.89' r='22.32' />

    <linearGradient
      id='HowItWorks1-2'
      gradientUnits='userSpaceOnUse'
      x1='54.5257'
      y1='159.9575'
      x2='246.3112'
      y2='118.4455'>
      <stop offset='0' stopColor='#00AECE' />
      <stop offset='0.4863' stopColor='#8A2FF1' />
      <stop offset='1' stopColor='#FD67FF' />
    </linearGradient>
    <circle fill='url(#HowItWorks1-2)' cx='228.98' cy='122.2' r='22.32' />

    <linearGradient
      id='HowItWorks1-3'
      gradientUnits='userSpaceOnUse'
      x1='43.0514'
      y1='106.9461'
      x2='234.8368'
      y2='65.4341'>
      <stop offset='0' stopColor='#00AECE' />
      <stop offset='0.4863' stopColor='#8A2FF1' />
      <stop offset='1' stopColor='#FD67FF' />
    </linearGradient>
    <path
      fill='url(#HowItWorks1-3)'
      d='M62.89,111.82c-2.21,0-4-1.79-4-4
			c0-3.67,0.23-7.36,0.68-10.97c0.27-2.19,2.27-3.76,4.47-3.47c2.19,0.27,3.75,2.27,3.47,4.46c-0.41,3.29-0.62,6.64-0.62,9.98
			C66.89,110.03,65.1,111.82,62.89,111.82z'
    />

    <linearGradient
      id='HowItWorks1-4'
      gradientUnits='userSpaceOnUse'
      x1='34.0243'
      y1='65.2407'
      x2='225.8097'
      y2='23.7287'>
      <stop offset='0' stopColor='#00AECE' />
      <stop offset='0.4863' stopColor='#8A2FF1' />
      <stop offset='1' stopColor='#FD67FF' />
    </linearGradient>
    <path
      fill='url(#HowItWorks1-4)'
      d='M69.19,79.91c-0.51,0-1.03-0.1-1.52-0.3
			c-2.04-0.84-3.01-3.18-2.17-5.22c2.96-7.17,6.88-13.93,11.64-20.1c1.35-1.75,3.86-2.07,5.61-0.72c1.75,1.35,2.07,3.86,0.72,5.61
			c-4.33,5.6-7.88,11.75-10.57,18.26C72.25,78.98,70.76,79.91,69.19,79.91z M199.15,46.75c-0.88,0-1.77-0.29-2.51-0.89
			c-5.5-4.45-11.56-8.15-18.02-10.99c-2.02-0.89-2.94-3.25-2.05-5.27c0.89-2.02,3.25-2.94,5.27-2.05c7.1,3.13,13.77,7.2,19.83,12.09
			c1.72,1.39,1.98,3.91,0.59,5.63C201.47,46.24,200.31,46.75,199.15,46.75z M96.03,45.13c-1.21,0-2.4-0.54-3.19-1.58
			c-1.34-1.76-0.99-4.27,0.77-5.6c6.2-4.71,13-8.56,20.19-11.46c2.05-0.82,4.38,0.17,5.21,2.21c0.83,2.05-0.17,4.38-2.21,5.21
			c-6.54,2.63-12.71,6.14-18.35,10.41C97.73,44.86,96.88,45.13,96.03,45.13z M158.94,29.07c-0.2,0-0.39-0.01-0.59-0.04
			c-6.93-1.03-14.14-1.14-21.11-0.33c-2.2,0.26-4.18-1.32-4.44-3.51c-0.26-2.19,1.32-4.18,3.51-4.44c7.66-0.9,15.59-0.77,23.21,0.36
			c2.19,0.32,3.69,2.36,3.37,4.54C162.6,27.64,160.89,29.06,158.94,29.07z'
    />

    <linearGradient
      id='HowItWorks1-5'
      gradientUnits='userSpaceOnUse'
      x1='41.8224'
      y1='101.268'
      x2='233.6078'
      y2='59.756'>
      <stop offset='0' stopColor='#00AECE' />
      <stop offset='0.4863' stopColor='#8A2FF1' />
      <stop offset='1' stopColor='#FD67FF' />
    </linearGradient>
    <path
      fill='url(#HowItWorks1-5)'
      d='M220.02,71.74
			c-1.42,0-2.79-0.75-3.51-2.08c-1.59-2.91-3.39-5.76-5.33-8.45c-1.29-1.79-0.89-4.29,0.9-5.58c1.79-1.29,4.29-0.89,5.58,0.9
			c2.14,2.96,4.12,6.09,5.87,9.3c1.06,1.94,0.35,4.37-1.59,5.43C221.33,71.58,220.67,71.74,220.02,71.74z'
    />

    <linearGradient
      id='HowItWorks1-6'
      gradientUnits='userSpaceOnUse'
      x1='43.9441'
      y1='111.0703'
      x2='235.7295'
      y2='69.5583'>
      <stop offset='0' stopColor='#00AECE' />
      <stop offset='0.4863' stopColor='#8A2FF1' />
      <stop offset='1' stopColor='#FD67FF' />
    </linearGradient>
    <polygon
      fill='url(#HowItWorks1-6)'
      points='201.59,62.99 217.68,63.35
				229.11,52.02 228.35,90.13 			'
    />
  </svg>
)
