export const HowItWorks2 = ({ className = '' }) => (
  <svg
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    viewBox='0 0 293.11 178.35'
    xmlSpace='preserve'
    enableBackground='new 0 0 293.11 178.35'
    className={className}>
    <circle fill='#FFFFFF' cx='134.56' cy='92.82' r='50.37' />
    <circle
      className='opacity-25'
      fill='#8A2FF1'
      cx='134.56'
      cy='92.82'
      r='50.37'
    />
    <circle fill='#8A2FF1' cx='134.56' cy='92.82' r='31.8' />
    <rect x='98.61' y='79.65' fill='none' width='70.31' height='37.68' />
    <text
      transform='matrix(1 0 0 1 124.118 105.0615)'
      fill='#FFFFFF'
      className='font-stolzl-bold [font-size:33px]'>
      2
    </text>
    <linearGradient
      id='HowItWorks2-1'
      gradientUnits='userSpaceOnUse'
      x1='193.9456'
      y1='110.0401'
      x2='265.19'
      y2='110.0401'>
      <stop offset='0' stopColor='#00AECE' />
      <stop offset='0.4863' stopColor='#8A2FF1' />
      <stop offset='1' stopColor='#FD67FF' />
    </linearGradient>
    <path
      fill='url(#HowItWorks2-1)'
      d='M203.01,83.49c5.57-5.61,13.3-9.07,21.85-9.07h9.41c17.1,0,30.92,13.86,30.92,30.97
			c0,8.51-3.5,16.28-9.07,21.85c-5.57,5.57-13.3,9.07-21.85,9.07h-9.41c-0.65,0-1.25,0-1.9-0.09l-13.13,8.9
			c-0.73,0.52-1.64,0.65-2.46,0.47c-0.13,0-0.3-0.04-0.43-0.13c-0.3-0.13-0.6-0.26-0.86-0.47c-0.13-0.09-0.3-0.17-0.39-0.3
			c-0.13-0.13-0.26-0.3-0.35-0.43c-0.39-0.56-0.56-1.17-0.56-1.81v-13.6c-6.65-5.66-10.84-14.08-10.84-23.45
			C193.95,96.83,197.4,89.06,203.01,83.49L203.01,83.49z'
    />
    <path
      fill='#FFFFFF'
      d='M232.81,116.14c0,0.86-0.35,1.68-0.95,2.29c-0.6,0.6-1.43,0.95-2.29,0.95
			c-0.86,0-1.68-0.35-2.29-0.95c-0.6-0.6-0.95-1.43-0.95-2.29c0-0.82,0.35-1.68,0.95-2.29c0.6-0.6,1.43-0.95,2.29-0.95
			c0.86,0,1.68,0.35,2.29,0.95C232.46,114.45,232.81,115.32,232.81,116.14z'
    />
    <path
      fill='#FFFFFF'
      d='M232.81,94.54v12.48c0,1.77-1.47,3.24-3.24,3.24s-3.24-1.47-3.24-3.24V94.54
			c0-1.77,1.47-3.24,3.24-3.24C231.34,91.3,232.81,92.77,232.81,94.54z'
    />

    <linearGradient
      id='HowItWorks2-2'
      gradientUnits='userSpaceOnUse'
      x1='-3238.3462'
      y1='66.1652'
      x2='-3188.0703'
      y2='66.1652'
      gradientTransform='matrix(-1 0 0 1 -3160.2307 0)'>
      <stop offset='0' stopColor='#00AECE' />
      <stop offset='0.4863' stopColor='#8A2FF1' />
      <stop offset='1' stopColor='#FD67FF' />
    </linearGradient>
    <path
      fill='url(#HowItWorks2-2)'
      d='M71.72,47.43
			c3.96,3.93,6.4,9.42,6.4,15.45c0,6.61-2.96,12.56-7.65,16.55v9.6c0,0.46-0.12,0.88-0.4,1.28c-0.06,0.09-0.15,0.21-0.24,0.3
			c-0.06,0.09-0.18,0.15-0.27,0.21c-0.18,0.15-0.4,0.24-0.61,0.34c-0.09,0.06-0.21,0.09-0.3,0.09c-0.58,0.12-1.22,0.03-1.74-0.34
			l-9.26-6.28c-0.46,0.06-0.88,0.06-1.34,0.06h-6.64c-6.03,0-11.49-2.47-15.42-6.4c-3.93-3.93-6.4-9.42-6.4-15.42
			c0-12.07,9.75-21.85,21.82-21.85h6.64C62.33,41.03,67.79,43.47,71.72,47.43L71.72,47.43z'
    />
    <path
      fill='#FFFFFF'
      d='M51.36,68.85c0.43-0.43,1.01-0.67,1.61-0.67c0.61,0,1.19,0.24,1.61,0.67
			c0.43,0.43,0.67,1.04,0.67,1.61c0,0.61-0.24,1.19-0.67,1.61c-0.43,0.43-1.01,0.67-1.61,0.67c-0.61,0-1.19-0.24-1.61-0.67
			c-0.43-0.43-0.67-1.01-0.67-1.61C50.69,69.89,50.94,69.28,51.36,68.85z'
    />
    <path
      fill='#FFFFFF'
      d='M52.98,52.94c1.25,0,2.29,1.04,2.29,2.29v8.81c0,1.25-1.04,2.29-2.29,2.29s-2.29-1.04-2.29-2.29
			v-8.81C50.69,53.98,51.73,52.94,52.98,52.94z'
    />
  </svg>
)
