export const HowItWorksIllustration = ({
  text1a = 'Connect',
  text1b = 'Pulse',
  text2a = 'Convert',
  text2b = 'Notifications',
  text3a = 'Complete',
  text3b = 'Tasks',
}: {
  text1a?: string
  text1b?: string
  text2a?: string
  text2b?: string
  text3a?: string
  text3b?: string
}) => (
  <svg version='1.1' x='0px' y='0px' viewBox='0 2206 1366 300'>
    {/* Wavy line */}
    <g>
      <defs>
        <rect
          id='SVGID_00000023281318169882201240000008249891017660820115_'
          y='2230.08'
          width='1366'
          height='287.4'
        />
      </defs>
      <clipPath id='SVGID_00000127739716011488912560000006971171917105651346_'>
        <use
          xmlnsXlink='http://www.w3.org/1999/xlink'
          xlinkHref='#SVGID_00000023281318169882201240000008249891017660820115_'
        />
      </clipPath>
      <path
        style={{
          clipPath:
            'url(#SVGID_00000127739716011488912560000006971171917105651346_)',
          fill: 'none',
          stroke: '#F1F1F2',
          strokeWidth: 31,
          strokeMiterlimit: 10,
        }}
        d='M-114.68,2366.28c0,0,243.28-165.75,678.32,0c422.96,161.15,819.18-48.08,819.18-48.08'
      />
      <linearGradient
        id='SVGID_1_'
        gradientUnits='userSpaceOnUse'
        x1='-115.2396'
        y1='2359.9302'
        x2='1383.2935'
        y2='2359.9302'>
        <stop offset='0' stopColor='#00AECE' />
        <stop offset='0.4863' stopColor='#8A2FF1' />
        <stop offset='1' stopColor='#FD67FF' />
      </linearGradient>
      <path
        style={{
          clipPath:
            'url(#SVGID_00000127739716011488912560000006971171917105651346_)',
          fill: 'none',
          stroke: 'url(#SVGID_1_)',
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
        d='M-114.68,2366.28c0,0,243.28-165.75,678.32,0c422.96,161.15,819.18-48.08,819.18-48.08'
      />{' '}
    </g>

    {/* Main circles and text */}
    <g>
      <circle style={{ fill: '#FFFFFF' }} cx='259.3' cy='2293.46' r='50.37' />
      <circle style={{ fill: '#FFFFFF' }} cx='683' cy='2406.04' r='50.37' />
      <circle style={{ fill: '#FFFFFF' }} cx='1106.7' cy='2406.04' r='50.37' />
      <circle
        style={{ opacity: 0.25, fill: '#00AECE' }}
        cx='259.3'
        cy='2293.46'
        r='50.37'
      />
      <circle
        style={{ opacity: 0.25, fill: '#8A2FF1' }}
        cx='683'
        cy='2406.04'
        r='50.37'
      />
      <circle
        style={{ opacity: 0.25, fill: '#FD67FF' }}
        cx='1106.7'
        cy='2406.04'
        r='50.37'
      />
      <circle style={{ fill: '#00AECE' }} cx='259.3' cy='2293.46' r='31.8' />
      <circle style={{ fill: '#8A2FF1' }} cx='683' cy='2406.04' r='31.8' />
      <circle style={{ fill: '#FD67FF' }} cx='1106.7' cy='2406.04' r='31.8' />
      <text transform='matrix(1 0 0 1 220.9417 2363.1836)'>
        <tspan
          x='0'
          y='0'
          className='font-helvetica-neue font-medium'
          style={{ fontSize: '19px' }}>
          {text1a}
        </tspan>
        <tspan
          x='13.39'
          y='20.09'
          className='font-helvetica-neue font-medium'
          style={{ fontSize: '19px' }}>
          {text1b}
        </tspan>
      </text>
      <text transform='matrix(1 0 0 1 643.6549 2481.5212)'>
        <tspan
          x={((10 - text2b.length) * 4).toString()}
          y='0'
          className='font-helvetica-neue font-medium'
          style={{ fontSize: '19px' }}>
          {text2a}
        </tspan>
        <tspan
          x={(-16 + (13 - text2b.length) * 4).toString()}
          y='20.09'
          className='font-helvetica-neue font-medium'
          style={{ fontSize: '19px' }}>
          {text2b}
        </tspan>
      </text>
      <text transform='matrix(1 0 0 1 1062.7421 2481.5212)'>
        <tspan
          x={(20 - text3b.length * 2.5).toString()}
          y='0'
          className='font-helvetica-neue font-medium'
          style={{ fontSize: '19px' }}>
          {text3a}
        </tspan>
        <tspan
          x={(30 - text3b.length * 2.5).toString()}
          y='20.09'
          className='font-helvetica-neue font-medium'
          style={{ fontSize: '19px' }}>
          {text3b}
        </tspan>
      </text>
      <text
        transform='matrix(1 0 0 1 251 2304.8066)'
        className='font-stolzl-bold'
        style={{
          fill: '#FFFFFF',
          fontSize: '33px',
          textAlign: 'center',
        }}>
        1
      </text>
      <text
        transform='matrix(1 0 0 1 673 2418.2788)'
        className='font-stolzl-bold'
        style={{
          fill: '#FFFFFF',
          fontSize: '33px',
          textAlign: 'center',
        }}>
        2
      </text>
      <text
        transform='matrix(1 0 0 1 1098 2418.2788)'
        className='font-stolzl-bold'
        style={{
          fill: '#FFFFFF',
          fontSize: '33px',
          textAlign: 'center',
        }}>
        3
      </text>
    </g>

    {/* 1: Connect Pulse */}
    <g>
      <circle
        xmlns='http://www.w3.org/2000/svg'
        fill='url(#SVGID_00000107555775090708093900000005713386217749977006_)'
        cx='176.88'
        cy='2294.52'
        r='22.32'
      />
      <linearGradient
        id='SVGID_00000049922001188616721590000006678357725241488001_'
        gradientUnits='userSpaceOnUse'
        x1='146.7709'
        y1='2250.8767'
        x2='338.5563'
        y2='2209.3647'>
        <stop offset='0' stopColor='#00AECE' />
        <stop offset='0.4863' stopColor='#8A2FF1' />
        <stop offset='1' stopColor='#FD67FF' />
      </linearGradient>
      <path
        fill='url(#SVGID_00000049922001188616721590000006678357725241488001_)'
        d='M181.94,2265.55    c-0.51,0-1.03-0.1-1.52-0.3c-2.04-0.84-3.01-3.18-2.17-5.22c2.96-7.17,6.88-13.93,11.64-20.1c1.35-1.75,3.86-2.07,5.61-0.72    c1.75,1.35,2.07,3.86,0.72,5.61c-4.33,5.6-7.88,11.75-10.57,18.26C185,2264.61,183.51,2265.55,181.94,2265.55z M311.89,2232.39    c-0.88,0-1.77-0.29-2.51-0.89c-5.5-4.45-11.56-8.15-18.02-10.99c-2.02-0.89-2.94-3.25-2.05-5.27c0.89-2.02,3.25-2.94,5.27-2.05    c7.1,3.13,13.77,7.2,19.83,12.09c1.72,1.39,1.98,3.91,0.59,5.63C314.22,2231.88,313.06,2232.39,311.89,2232.39z M208.78,2230.76    c-1.21,0-2.4-0.54-3.19-1.58c-1.34-1.76-0.99-4.27,0.77-5.6c6.2-4.71,13-8.56,20.19-11.46c2.05-0.82,4.38,0.17,5.21,2.21    c0.83,2.05-0.17,4.38-2.21,5.21c-6.54,2.63-12.71,6.14-18.35,10.41C210.47,2230.5,209.62,2230.76,208.78,2230.76z M271.69,2214.7    c-0.2,0-0.39-0.01-0.59-0.04c-6.93-1.03-14.14-1.14-21.11-0.33c-2.2,0.26-4.18-1.32-4.44-3.51c-0.26-2.19,1.32-4.18,3.51-4.44    c7.66-0.9,15.59-0.77,23.21,0.36c2.19,0.32,3.69,2.36,3.37,4.54C275.35,2213.27,273.64,2214.7,271.69,2214.7z'
      />

      <linearGradient
        id='SVGID_00000107555775090708093900000005713386217749977006_'
        gradientUnits='userSpaceOnUse'
        x1='154.569'
        y1='2286.9041'
        x2='346.3544'
        y2='2245.3921'>
        <stop offset='0' stopColor='#00AECE' />
        <stop offset='0.4863' stopColor='#8A2FF1' />
        <stop offset='1' stopColor='#FD67FF' />
      </linearGradient>
      <path
        fill='url(#SVGID_00000107555775090708093900000005713386217749977006_)'
        d='M332.77,2257.38    c-1.42,0-2.79-0.75-3.51-2.08c-1.59-2.91-3.39-5.76-5.33-8.45c-1.29-1.79-0.89-4.29,0.9-5.58c1.79-1.29,4.29-0.89,5.58,0.9    c2.14,2.96,4.12,6.09,5.87,9.3c1.06,1.94,0.35,4.37-1.59,5.43C334.07,2257.22,333.42,2257.38,332.77,2257.38z'
      />
      <linearGradient
        id='SVGID_00000003817574611004750640000013827163997673137292_'
        gradientUnits='userSpaceOnUse'
        x1='156.6907'
        y1='2296.7065'
        x2='348.4762'
        y2='2255.1946'>
        <stop offset='0' stopColor='#00AECE' />
        <stop offset='0.4863' stopColor='#8A2FF1' />
        <stop offset='1' stopColor='#FD67FF' />
      </linearGradient>
      <polygon
        fill='url(#SVGID_00000003817574611004750640000013827163997673137292_)'
        points='314.33,2248.63 330.42,2248.99      341.85,2237.66 341.09,2275.76    '
      />

      <circle
        fill='url(#SVGID_00000160907295704511504720000011605197203998539158_)'
        cx='176.88'
        cy='2294.52'
        r='22.32'
      />
      <linearGradient
        id='SVGID_00000156557174905596916760000005419440815164526218_'
        gradientUnits='userSpaceOnUse'
        x1='167.2724'
        y1='2345.5938'
        x2='359.0578'
        y2='2304.0815'>
        <stop offset='0' stopColor='#00AECE' />
        <stop offset='0.4863' stopColor='#8A2FF1' />
        <stop offset='1' stopColor='#FD67FF' />
      </linearGradient>
      <circle
        fill='url(#SVGID_00000156557174905596916760000005419440815164526218_)'
        cx='341.73'
        cy='2307.83'
        r='22.32'
      />
    </g>

    {/* 2: Convert Notifications */}
    <g>
      <linearGradient
        id='SVGID_00000099634371312725327970000012344262681296746370_'
        gradientUnits='userSpaceOnUse'
        x1='742.3854'
        y1='2423.2573'
        x2='813.6298'
        y2='2423.2573'>
        <stop offset='0' stopColor='#00AECE' />
        <stop offset='0.4863' stopColor='#8A2FF1' />
        <stop offset='1' stopColor='#FD67FF' />
      </linearGradient>
      <path
        style={{
          fill: 'url(#SVGID_00000099634371312725327970000012344262681296746370_)',
        }}
        d='M751.45,2396.7 c5.57-5.61,13.3-9.07,21.85-9.07h9.41c17.1,0,30.92,13.86,30.92,30.97c0,8.51-3.5,16.28-9.07,21.85 c-5.57,5.57-13.3,9.07-21.85,9.07h-9.41c-0.65,0-1.25,0-1.9-0.09l-13.13,8.9c-0.73,0.52-1.64,0.65-2.46,0.47 c-0.13,0-0.3-0.04-0.43-0.13c-0.3-0.13-0.6-0.26-0.86-0.47c-0.13-0.09-0.3-0.17-0.39-0.3c-0.13-0.13-0.26-0.3-0.35-0.43 c-0.39-0.56-0.56-1.17-0.56-1.81v-13.6c-6.65-5.66-10.84-14.08-10.84-23.45C742.39,2410.05,745.84,2402.28,751.45,2396.7 L751.45,2396.7z'
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d='M781.25,2429.35c0,0.86-0.35,1.68-0.95,2.29c-0.6,0.6-1.43,0.95-2.29,0.95 c-0.86,0-1.68-0.34-2.29-0.95s-0.95-1.43-0.95-2.29c0-0.82,0.35-1.68,0.95-2.29c0.6-0.6,1.42-0.95,2.29-0.95 c0.86,0,1.68,0.35,2.29,0.95C780.9,2427.67,781.25,2428.53,781.25,2429.35z'
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d='M781.25,2407.76v12.48c0,1.77-1.47,3.24-3.24,3.24c-1.77,0-3.24-1.47-3.24-3.24v-12.48 c0-1.77,1.47-3.24,3.24-3.24C779.78,2404.52,781.25,2405.99,781.25,2407.76z'
      />
      <linearGradient
        id='SVGID_00000047048150872379555010000000365651646693006741_'
        gradientUnits='userSpaceOnUse'
        x1='650.9381'
        y1='2379.3823'
        x2='701.214'
        y2='2379.3823'
        gradientTransform='matrix(-1 0 0 1 1277.4934 0)'>
        <stop offset='0' stopColor='#00AECE' />
        <stop offset='0.4863' stopColor='#8A2FF1' />
        <stop offset='1' stopColor='#FD67FF' />
      </linearGradient>
      <path
        style={{
          fill: 'url(#SVGID_00000047048150872379555010000000365651646693006741_)',
        }}
        d='M620.16,2360.64 c3.96,3.93,6.4,9.42,6.4,15.45c0,6.61-2.96,12.56-7.65,16.55v9.6c0,0.46-0.12,0.88-0.4,1.28c-0.06,0.09-0.15,0.21-0.24,0.31 c-0.06,0.09-0.18,0.15-0.27,0.21c-0.18,0.15-0.4,0.24-0.61,0.34c-0.09,0.06-0.21,0.09-0.3,0.09c-0.58,0.12-1.22,0.03-1.74-0.34 l-9.26-6.28c-0.46,0.06-0.88,0.06-1.34,0.06h-6.64c-6.03,0-11.49-2.47-15.42-6.4c-3.93-3.93-6.4-9.42-6.4-15.42 c0-12.07,9.75-21.85,21.82-21.85h6.64C610.77,2354.24,616.23,2356.68,620.16,2360.64L620.16,2360.64z'
      />
      <path
        fill='#FFFFFF'
        d='M599.8,2382.07c0.43-0.43,1.01-0.67,1.61-0.67c0.61,0,1.19,0.24,1.61,0.67    c0.43,0.43,0.67,1.04,0.67,1.61c0,0.61-0.24,1.19-0.67,1.61c-0.43,0.43-1.01,0.67-1.61,0.67c-0.61,0-1.19-0.24-1.61-0.67    c-0.43-0.43-0.67-1.01-0.67-1.61C599.13,2383.11,599.38,2382.5,599.8,2382.07z'
      />
      <path
        fill='#FFFFFF'
        d='M601.42,2366.16c1.25,0,2.29,1.04,2.29,2.29v8.81c0,1.25-1.04,2.29-2.29,2.29s-2.29-1.04-2.29-2.29    v-8.81C599.13,2367.2,600.17,2366.16,601.42,2366.16z'
      />
    </g>

    {/* 3: Complete Tasks */}
    <g>
      <linearGradient
        id='SVGID_00000083055734194092279140000009207378530827669944_'
        gradientUnits='userSpaceOnUse'
        x1='1002.264'
        y1='2420.7241'
        x2='1048.0353'
        y2='2420.7241'>
        <stop offset='0' stopColor='#00AECE' />
        <stop offset='0.4863' stopColor='#8A2FF1' />
        <stop offset='1' stopColor='#FD67FF' />
      </linearGradient>
      <circle
        style={{
          fill: 'url(#SVGID_00000083055734194092279140000009207378530827669944_)',
        }}
        cx='1025.15'
        cy='2420.72'
        r='22.89'
      />
      <polyline
        style={{
          fill: 'none',
          stroke: '#FFFFFF',
          strokeWidth: 4,
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
        points='1015.04,2422.19 1020.21,2427.37 1034.16,2413.43'
      />
      <linearGradient
        id='SVGID_00000001659576853409200570000012639908612314679449_'
        gradientUnits='userSpaceOnUse'
        x1='1166.6583'
        y1='2387.7251'
        x2='1233.1599'
        y2='2387.7251'>
        <stop offset='0' stopColor='#00AECE' />
        <stop offset='0.4863' stopColor='#8A2FF1' />
        <stop offset='1' stopColor='#FD67FF' />
      </linearGradient>
      <circle
        style={{
          fill: 'url(#SVGID_00000001659576853409200570000012639908612314679449_)',
        }}
        cx='1199.91'
        cy='2387.73'
        r='33.25'
      />
      <polyline
        style={{
          fill: 'none',
          stroke: '#FFFFFF',
          strokeWidth: 4,
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
        points='1185.22,2389.86 1192.74,2397.38 1212.99,2377.13'
      />
    </g>
  </svg>
)
