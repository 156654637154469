export const HowItWorks3 = ({ className = '' }) => (
  <svg
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    x='0px'
    y='0px'
    viewBox='0 0 293.11 178.35'
    enableBackground='new 0 0 293.11 178.35'
    xmlSpace='preserve'
    className={className}>
    <circle fill='#FFFFFF' cx='135.54' cy='96.24' r='50.37' />
    <circle
      className='opacity-25'
      fill='#FD67FF'
      cx='135.54'
      cy='96.24'
      r='50.37'
    />
    <circle fill='#FD67FF' cx='135.54' cy='96.24' r='31.8' />
    <rect x='99.39' y='83.07' fill='none' width='70.31' height='37.68' />
    <text
      transform='matrix(1 0 0 1 124.8439 108.4768)'
      fill='#FFFFFF'
      className='font-stolzl-bold [font-size:33px]'>
      3
    </text>
    <linearGradient
      id='HowItWorks3-1'
      gradientUnits='userSpaceOnUse'
      x1='31.1078'
      y1='110.9224'
      x2='76.879'
      y2='110.9224'>
      <stop offset='0' stopColor='#00AECE' />
      <stop offset='0.4863' stopColor='#8A2FF1' />
      <stop offset='1' stopColor='#FD67FF' />
    </linearGradient>
    <circle fill='url(#HowItWorks3-1)' cx='53.99' cy='110.92' r='22.89' />
    <polyline
      fill='none'
      stroke='#FFFFFF'
      strokeWidth='4'
      strokeLinecap='round'
      strokeMiterlimit='10'
      points='43.88,112.39
        49.06,117.57 63,103.63 		'
    />
    <linearGradient
      id='HowItWorks3-2'
      gradientUnits='userSpaceOnUse'
      x1='195.5021'
      y1='77.9232'
      x2='262.0037'
      y2='77.9232'>
      <stop offset='0' stopColor='#00AECE' />
      <stop offset='0.4863' stopColor='#8A2FF1' />
      <stop offset='1' stopColor='#FD67FF' />
    </linearGradient>
    <circle fill='url(#HowItWorks3-2)' cx='228.75' cy='77.92' r='33.25' />
    <polyline
      fill='none'
      stroke='#FFFFFF'
      strokeWidth='4'
      strokeLinecap='round'
      strokeMiterlimit='10'
      points='214.06,80.06
        221.58,87.58 241.84,67.32 		'
    />
  </svg>
)
