import { Card, CardFooter, CardHeader } from '@/web/components/ui/card'
import { cn } from '@/web/libs/utils'

export type Feature = {
  icon: () => JSX.Element
  title: string
  description: string | JSX.Element
}

export const FeatureCards = ({
  features,
  maxPerRow = 3,
}: {
  features: Feature[]
  maxPerRow?: number
}) => {
  return (
    <div
      className={cn(
        'mx-auto grid grid-cols-1 gap-6 pt-16 [grid-template-rows:min-content_min-content_1fr] md:grid-cols-2 xl:pb-20 2xl:gap-12 2xl:px-40',
        maxPerRow >= 3 && 'xl:grid-cols-3'
      )}>
      {features.map(feature => (
        <Card
          key={feature.title}
          variant='primary'
          className='grid items-start text-center [grid-column:span_1] [grid-row:span_3] [grid-template-columns:1fr] [grid-template-rows:subgrid]'>
          <CardHeader className='flex h-20 w-full flex-col items-center justify-center'>
            <feature.icon />
          </CardHeader>
          <h4 className='font-semibold'>{feature.title}</h4>
          <CardFooter className='text-muted-foreground'>
            {feature.description}
          </CardFooter>
        </Card>
      ))}
    </div>
  )
}
